body,
.root {
	overflow-x: hidden;
	max-width: 100vw;
	background-color: aqua;
}

#menu {
	display: none;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #6653FF #201B52;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 10px;
  width: 10px;
}
*::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #201B52;
}

*::-webkit-scrollbar-track:hover {
  background-color: #201B52;
}

*::-webkit-scrollbar-track:active {
  background-color: #201B52;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #6653FF;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #4F40C5;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #4280FF;
}
